// src/utils/songUtils.js

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getAllSongs = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/songs`);
    if (!response.ok) {
      // Attempt to parse the error message from the response
      let errorMessage = `HTTP error! Status: ${response.status}`;
      try {
        const errorData = await response.json();
        errorMessage += `, Message: ${errorData.error}`;
      } catch (parseError) {
        // If parsing fails, retain the original error message
        console.error('Error parsing error response:', parseError);
      }
      throw new Error(errorMessage);
    }
    const songs = await response.json();
    return songs;
  } catch (error) {
    console.error('Error fetching all songs:', error);
    return [];
  }
};

export const getSongById = async (id) => {
  try {
    const response = await fetch(`${API_BASE_URL}/songs/${id}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const song = await response.json();
    return song;
  } catch (error) {
    console.error('Error getting song by ID:', error);
    return null;
  }
};

export const searchSongs = async (query) => {
  try {
    const response = await fetch(`${API_BASE_URL}/songs/search?q=${encodeURIComponent(query)}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const songs = await response.json();
    return songs;
  } catch (error) {
    console.error('Error searching songs:', error);
    return [];
  }
};

export const getTopSongs = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/songs/top`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const songs = await response.json();
    return songs;
  } catch (error) {
    console.error('Error getting top songs:', error);
    return [];
  }
};

export const getTrendingSongs = async (page = 1, limit = 20) => {
  try {
    const response = await fetch(`${API_BASE_URL}/songs/trending?page=${page}&limit=${limit}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const songs = await response.json();
    return songs;
  } catch (error) {
    console.error('Error getting trending songs:', error);
    return [];
  }
};

export const getRandomSongs = async (count = 1) => {
  try {
    const response = await fetch(`${API_BASE_URL}/songs/random?count=${count}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const songs = await response.json();
    return songs;
  } catch (error) {
    console.error('Error getting random songs:', error);
    return [];
  }
};