// src/pages/CategoryPage.js

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import SongCard from '../components/SongCard';
import { getAllSongs } from '../utils/songUtils';
import TopText from '../components/TopText'; 
import { tagIcons } from '../constants/tagConfig';

// Styled Components
const PageContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #E3E3E3; /* Pale Stone Gray */
  width: 100%;
  box-sizing: border-box;
`;

const SongGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;
  width: calc(100% - 40px); /* Account for padding */
  max-width: 1200px;
  margin: 20px auto 0;
  justify-items: center;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 30px;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    gap: 30px;
    width: calc(100% - 20px); /* Smaller padding on mobile */
  }
`;

const EmptyMessage = styled.p`
  font-family: 'Rubik', sans-serif;
  color: #6A7D8B; /* Muted Slate Blue */
  font-size: 18px;
  margin-top: 30px;
  text-align: center;
`;

const Sentinel = styled.div`
  height: 1px;
`;

const normalizeUrl = (url) => {
  if (!url) return url;
  return url.replace(/^www\./, '');
};

function CategoryPage() {
  const { category } = useParams();
  const [allSongs, setAllSongs] = useState([]);
  const [visibleSongs, setVisibleSongs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const observer = useRef();

  const songsPerLoad = 8;

  const capitalizedCategory = category.charAt(0).toUpperCase() + category.slice(1);
  const IconComponent = tagIcons[capitalizedCategory];

  useEffect(() => {
    const fetchSongs = async () => {
      try {
        setLoading(true);
        setError(null);
        const all = await getAllSongs();
        
        const filteredSongs = all.filter((song) => {
          // Ensure song URLs are normalized when comparing
          if (song.song_file_wm) {
            song.song_file_wm = normalizeUrl(song.song_file_wm);
          }
          if (song.song_art) {
            song.song_art = normalizeUrl(song.song_art);
          }
          return song.song_tags.includes(capitalizedCategory);
        });
        
        // Sort songs: featured first, then by date
        const sortedSongs = filteredSongs.sort((a, b) => {
          // If one is featured and other isn't, featured comes first
          if (a.song_featured && !b.song_featured) return -1;
          if (!a.song_featured && b.song_featured) return 1;
          // If both are featured or both aren't, sort by date
          return new Date(b.date_added) - new Date(a.date_added);
        });
        
        setAllSongs(sortedSongs);
        setVisibleSongs(sortedSongs.slice(0, songsPerLoad));
      } catch (err) {
        console.error('Error fetching songs:', err);
        setError('Failed to fetch songs. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchSongs();
  }, [category, capitalizedCategory]);

  const loadMoreSongs = useCallback(() => {
    setVisibleSongs((prevSongs) => {
      const nextSongs = allSongs.slice(
        prevSongs.length,
        prevSongs.length + songsPerLoad
      );
      return [...prevSongs, ...nextSongs];
    });
  }, [allSongs]);

  const sentinelRef = useRef();

  useEffect(() => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (
        entries[0].isIntersecting &&
        visibleSongs.length < allSongs.length
      ) {
        loadMoreSongs();
      }
    });

    if (sentinelRef.current) {
      observer.current.observe(sentinelRef.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [loading, visibleSongs, allSongs, loadMoreSongs]);

  return (
    <PageContainer>
      <Helmet>
        <title>{capitalizedCategory} | Tempo Stock</title>
        <meta name="description" content={`Browse our collection of ${capitalizedCategory.toLowerCase()} tracks on Tempo Stock.`} />
        <meta property="og:title" content={`${capitalizedCategory} | Tempo Stock`} />
        <meta property="og:description" content={`Browse our collection of ${capitalizedCategory.toLowerCase()} tracks on Tempo Stock.`} />
        <meta name="twitter:title" content={`${capitalizedCategory} | Tempo Stock`} />
        <meta name="twitter:description" content={`Browse our collection of ${capitalizedCategory.toLowerCase()} tracks on Tempo Stock.`} />
      </Helmet>

      {IconComponent && <TopText icon={IconComponent} title={capitalizedCategory} />}

      {loading && <EmptyMessage>Loading songs...</EmptyMessage>}
      {error && <EmptyMessage>{error}</EmptyMessage>}

      {!loading && !error && (
        <>
          {allSongs.length > 0 ? (
            <>
              <SongGrid>
                {visibleSongs.map((song) => (
                  <SongCard 
                    key={song.song_id} 
                    song={song} 
                    shouldTrackPlay={true}
                    isFeatured={song.song_featured}
                  />
                ))}
              </SongGrid>
              {visibleSongs.length < allSongs.length && (
                <>
                  <Sentinel ref={sentinelRef} />
                  <EmptyMessage>Loading more songs...</EmptyMessage>
                </>
              )}
            </>
          ) : (
            <EmptyMessage>No songs found in this category.</EmptyMessage>
          )}
        </>
      )}
    </PageContainer>
  );
}

export default CategoryPage;