import React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { HiRadio } from 'react-icons/hi2';
import SongCarousel from '../components/SongCarousel';

const PageContainer = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #1A1A1A;
  color: white;
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 60px;
  box-sizing: border-box;
`;

const PageTitle = styled.h1`
  text-align: center;
  font-size: 2rem;
  margin-bottom: 40px;
  color: white;

  @media (max-width: 768px) {
    font-size: 1.4rem;
    margin-bottom: 0;
    margin-left: 8px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  
  svg {
    width: 2.8rem;
    height: 2.8rem;
    color: white;
    opacity: 0.9;
  }

  @media (max-width: 768px) {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    height: 1.4rem;
    svg {
      width: 1.4rem;
      height: 1.4rem;
      position: relative;
      top: 1px;
    }
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;

  @media (max-width: 768px) {
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    margin-bottom: 70px;
  }
`;

function RadioPage() {
  return (
    <PageContainer>
      <Helmet>
        <title>Tempo Radio | Continuous Music</title>
        <meta name="description" content="Listen to a continuous stream of curated music on Tempo Radio." />
        <meta property="og:title" content="Tempo Radio | Continuous Music" />
        <meta property="og:description" content="Listen to a continuous stream of curated music on Tempo Radio." />
        <meta name="twitter:title" content="Tempo Radio | Continuous Music" />
        <meta name="twitter:description" content="Listen to a continuous stream of curated music on Tempo Radio." />
      </Helmet>
      <ContentContainer>
        <HeaderContainer>
          <IconContainer>
            <HiRadio />
          </IconContainer>
          <PageTitle>tempo radio</PageTitle>
        </HeaderContainer>
        <SongCarousel />
      </ContentContainer>
    </PageContainer>
  );
}

export default RadioPage; 