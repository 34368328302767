// src/components/SongCard.js

import React, { useState, useRef, useEffect, useImperativeHandle } from 'react';
import styled from 'styled-components';
import { FaPlay, FaPause } from 'react-icons/fa';
import { HiOutlineFire, HiStar } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { useSongContext } from '../SongContext';
import { getSignedUrl } from '../utils/getSignedUrl';

// Styled Components (unchanged)
const CardLink = styled(Link)`
  display: block;
  width: 100%;
  aspect-ratio: 0.714; /* This maintains the 250/350 ratio */
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  background: #2E2E2E;
  color: white;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  transition: background-image 0.3s ease-in-out;
  min-height: 350px;
  
  @media (max-width: 600px) {
    aspect-ratio: 1; /* Make it square on mobile */
    min-height: unset; /* Remove min-height to let aspect-ratio control size */
    width: 100%; /* Take full width of container */
  }
`;

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: ${props => props.$blurDataUrl ? `url(${props.$blurDataUrl})` : 'none'};
  background-size: cover;
  background-position: center;
  filter: ${props => props.$isLoading ? 'blur(10px)' : 'none'};
  transition: filter 0.3s ease-out;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(46, 46, 46, 0.4);
    z-index: 0;
  }
`;

const MainImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: ${props => props.$bgImage ? `url(${props.$bgImage})` : 'none'};
  background-size: cover;
  background-position: center;
  opacity: ${props => props.$isLoaded ? 1 : 0};
  transition: opacity 0.3s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(46, 46, 46, 0.4);
    z-index: 0;
  }
`;

const RankingNumber = styled.div`
  display: ${(props) => (props.hidden || props.ranking === undefined || props.ranking === null ? 'none' : 'block')};
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: white;
  color: #6B7280;
  padding: 4px 8px;
  border-radius: 8px;
  font-weight: 600;
  z-index: 3;
  border: 1px solid #E5E7EB;
  min-width: 24px;
  text-align: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const FeaturedBadge = styled(RankingNumber)`
  display: ${(props) => (props.hidden || !props.isFeatured ? 'none' : 'flex')};
  left: ${(props) => props.ranking ? '70px' : '10px'};  // Move right if there's also a ranking
  min-width: unset;
  padding: 4px 10px;
  background-color: transparent;
  color: white;
  border-color: white;
  white-space: nowrap;
  font-size: 14px;
  
  svg {
    color: white;
  }
`;

const PlayButton = styled.button`
  position: absolute;
  bottom: 175px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(46, 46, 46, 0.6);
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px; 
  cursor: pointer;
  color: white;
  font-size: 18px;
  z-index: 3;
  display: ${props => props.$hidePlayControls ? 'none' : 'flex'};
  justify-content: center;
  align-items: center;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  
  /* Add className for trending section override */
  &.play-button {
    bottom: 175px;
  }
  
  /* Adjust positioning and size for mobile */
  @media (max-width: 768px) {
    bottom: 45%; /* Adjust for square aspect ratio */
    width: 48px;
    height: 48px;
    padding: 0;
    display: ${props => props.$hidePlayControls ? 'none' : 'grid'};
    place-items: center;
  }
`;

const ProgressBarContainer = styled.div`
  position: absolute;
  bottom: 100px;
  left: 10px;
  right: 10px;
  height: 25px;
  z-index: 3;
  cursor: pointer;
  display: ${props => props.$hidePlayControls ? 'none' : 'flex'};
  flex-direction: column;
  align-items: center;
  pointer-events: auto;

  @media (max-width: 768px) {
    bottom: 80px; /* Adjust for square aspect ratio */
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 2.5px;
  overflow: hidden;
  pointer-events: auto;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    height: 100%;
    width: ${(props) => props.$progress}%;
    background: white;
    transition: width 0.1s linear;
    pointer-events: none;
  }

  &:hover {
    height: 7px;
  }
`;

const TimerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`;

const TimeText = styled.div`
  color: #D8D8D8;
  font-size: 0.7em;
  padding: 0 2px;
`;

const InfoOverlay = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 15px 10px; 
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    margin: 5px 0;
    color: white;
    text-align: center;
    max-width: 90%;
    font-size: 1.1em;
  }
`;

const BpmKeyContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 5px;
`;

const BpmKeyPill = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 8px;
  color: #D8D8D8;
  font-size: 0.8em;
`;

// Add URL normalization
const normalizeUrl = (url) => {
  if (!url) return url;
  return url.replace(/^www\./, '');
};

// Add this function at the top level
const trackPlay = async (songId, playDuration, shouldTrack = true) => {
  console.log('Tracking play:', { songId, playDuration, shouldTrack });
  
  if (!shouldTrack) {
    console.log('Play tracking disabled');
    return;
  }

  try {
    const token = localStorage.getItem('token');
    const url = `${process.env.REACT_APP_API_BASE_URL}/songs/${songId}/play`;

    const headers = {
      'Content-Type': 'application/json'
    };

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify({ playDuration })
    });

    if (!response.ok) {
      console.error('Play tracking failed:', {
        status: response.status,
        statusText: response.statusText
      });
      return;
    }

    await response.json();
    console.log('Play tracked successfully for song:', songId);
  } catch (error) {
    console.error('Error tracking play:', {
      error: error.message,
      songId,
      playDuration
    });
  }
};

const SongCard = React.forwardRef(({ 
  song, 
  isPlaying, 
  onEnded, 
  hidePlayControls = false, 
  ranking, 
  shouldTrackPlay = true,
  isRadioCard = false,
  isFeatured = false
}, ref) => {
  // State
  const [localIsPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const [audioSrc, setAudioSrc] = useState('');
  const [bgImageSrc, setBgImageSrc] = useState('');
  const [imageLoaded, setImageLoaded] = useState(false);
  const [blurDataUrl, setBlurDataUrl] = useState('');
  const [isAudioReady, setIsAudioReady] = useState(false);
  const loadingRef = useRef(false);
  const [playStartTime, setPlayStartTime] = useState(null);

  // Add a ref to track whether we've already tracked this play session
  const hasTrackedRef = useRef(false);

  // Refs
  const audioRef = useRef(null);
  const cardRef = useRef(null);
  const progressBarRef = useRef(null);

  // Context
  const { currentSongId, setCurrentSongId } = useSongContext();

  // Define loadAudio function at component level
  const loadAudio = React.useCallback(async () => {
    if (!audioRef.current || !song.song_file_wm || loadingRef.current) return false;
    
    try {
        loadingRef.current = true;
        const normalizedSongFile = normalizeUrl(song.song_file_wm);
        const signedAudioUrl = await getSignedUrl(normalizedSongFile);
        
        if (signedAudioUrl && audioRef.current) {
            // First, remove any existing source
            audioRef.current.src = '';
            audioRef.current.load();
            
            // Set new source
            audioRef.current.src = signedAudioUrl;
            setAudioSrc(signedAudioUrl);
            
            // Wait for both metadata and canplay events
            await Promise.all([
                new Promise((resolve) => {
                    const handleMetadata = () => {
                        audioRef.current.removeEventListener('loadedmetadata', handleMetadata);
                        resolve();
                    };
                    audioRef.current.addEventListener('loadedmetadata', handleMetadata);
                }),
                new Promise((resolve) => {
                    const handleCanPlay = () => {
                        audioRef.current.removeEventListener('canplay', handleCanPlay);
                        resolve();
                    };
                    audioRef.current.addEventListener('canplay', handleCanPlay);
                })
            ]);
            
            // Start loading the audio
            audioRef.current.load();
            
            // Set ready state only after both events have fired
            setIsAudioReady(true);
            return true;
        }
        return false;
    } catch (error) {
        console.error('Error loading audio:', error);
        setIsAudioReady(false);
        return false;
    } finally {
        loadingRef.current = false;
    }
  }, [song.song_file_wm]);

  // Create audio element imperatively
  useEffect(() => {
    const audio = new Audio();
    audio.preload = 'auto';
    audioRef.current = audio;

    // Set up audio event listeners
    const handleLoadedMetadata = () => {
      console.log('Audio metadata loaded');
      setDuration(audio.duration);
      setIsAudioReady(true);
    };

    const handleTimeUpdate = () => {
      if (audio.duration && !isNaN(audio.duration)) {
        const time = audio.currentTime;
        if (!isNaN(time)) {
          setCurrentTime(time);
          setProgress((time / audio.duration) * 100);
        }
      }
    };

    const handleEnded = async () => {
      console.log('Audio ended');
      setIsPlaying(false);
      setCurrentTime(0);
      setProgress(0);
      if (onEnded) {
        onEnded();
      }
    };

    const handleError = (error) => {
      console.error('Audio error:', error, audio.error);
      setIsPlaying(false);
      setProgress(0);
      setCurrentTime(0);
      setIsAudioReady(false);
      setPlayStartTime(null);
      hasTrackedRef.current = false;
    };

    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('ended', handleEnded);
    audio.addEventListener('error', handleError);

    return () => {
      audio.pause();
      audio.src = '';
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('ended', handleEnded);
      audio.removeEventListener('error', handleError);
    };
  }, [onEnded]); // Add onEnded to dependencies

  // Handle audio source changes
  useEffect(() => {
    loadAudio();
  }, [loadAudio]);

  // Expose play/pause methods
  useImperativeHandle(ref, () => ({
    play: async () => {
      try {
        if (!isAudioReady) {
          const success = await loadAudio();
          if (!success) return;
        }

        if (audioRef.current) {
          await audioRef.current.play();
          setPlayStartTime(Date.now());
          setIsPlaying(true);
        }
      } catch (error) {
        console.error('Error playing audio:', error);
        setIsPlaying(false);
      }
    },
    pause: () => {
      if (audioRef.current) {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    },
    get currentTime() {
      return audioRef.current?.currentTime || 0;
    },
    set currentTime(value) {
      if (audioRef.current && isAudioReady) {
        audioRef.current.currentTime = value;
      }
    },
    get duration() {
      return audioRef.current?.duration || 0;
    },
    get isReady() {
      return isAudioReady;
    },
    addEventListener: (event, handler) => {
      if (audioRef.current) {
        audioRef.current.addEventListener(event, handler);
      }
    },
    removeEventListener: (event, handler) => {
      if (audioRef.current) {
        audioRef.current.removeEventListener(event, handler);
      }
    }
  }), [isAudioReady, loadAudio]); // Added loadAudio to dependencies

  // Handle external play state changes with memoized loadAudio
  useEffect(() => {
    if (isPlaying !== undefined && isPlaying !== localIsPlaying) {
      if (isPlaying && !localIsPlaying && audioRef.current) {
        if (isAudioReady) {
          audioRef.current.play().then(() => {
            setPlayStartTime(Date.now());
            setIsPlaying(true);
          }).catch(error => {
            console.error('Playback error:', { 
              error: error.message,
              songId: song.song_id 
            });
            setIsPlaying(false);
          });
        } else {
          loadAudio().then(success => {
            if (success && audioRef.current) {
              audioRef.current.play().then(() => {
                setPlayStartTime(Date.now());
                setIsPlaying(true);
              }).catch(error => {
                console.error('Playback error after load:', error);
                setIsPlaying(false);
              });
            }
          });
        }
      }
      
      if (!isPlaying && localIsPlaying && audioRef.current) {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    }
  }, [isPlaying, localIsPlaying, song.song_id, isAudioReady, loadAudio]);

  // Log prop changes
  useEffect(() => {
    console.log('SongCard updated:', { 
      songId: song.song_id, 
      isPlaying 
    });
  }, [song.song_id, shouldTrackPlay, isPlaying, hidePlayControls, ranking, isRadioCard]);

  // Pause this card when a different song starts playing
  useEffect(() => {
    if (currentSongId && currentSongId !== song.song_id && localIsPlaying) {
      console.log('Pausing song:', { currentSongId, songId: song.song_id });
      if (audioRef.current) {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    }
  }, [currentSongId, song.song_id, localIsPlaying]);

  // Track plays when stopping playback
  useEffect(() => {
    console.log('Play state:', { 
      songId: song.song_id,
      isPlaying: localIsPlaying 
    });
    
    const handlePlayEnd = () => {
      if (playStartTime && shouldTrackPlay && !hasTrackedRef.current) {
        const playDuration = Math.floor((Date.now() - playStartTime) / 1000);
        if (playDuration >= 5) {
          hasTrackedRef.current = true;
          trackPlay(song.song_id, playDuration, shouldTrackPlay);
        } else {
          console.log('Play duration too short:', { songId: song.song_id, playDuration });
        }
        setPlayStartTime(null);
      }
    };

    // Only track when stopping playback
    if (!localIsPlaying && playStartTime) {
      handlePlayEnd();
    }

    // Reset the tracking flag when starting a new play session
    if (localIsPlaying && playStartTime) {
      hasTrackedRef.current = false;
    }

    // Cleanup function that runs on unmount
    return () => {
      if (localIsPlaying && playStartTime) {
        handlePlayEnd();
      }
    };
  }, [localIsPlaying, playStartTime, song.song_id, shouldTrackPlay]);

  // Generate a tiny blur placeholder
  const generateBlurPlaceholder = (width = 10) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = Math.floor((width * 350) / 250); // Maintain aspect ratio

    ctx.fillStyle = '#2E2E2E';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    return canvas.toDataURL('image/jpeg', 0.1);
  };

  // Load the image
  useEffect(() => {
    if (song.song_art) {
      // Set blur placeholder immediately
      setBlurDataUrl(generateBlurPlaceholder());

      const loadArtwork = async () => {
        try {
          const normalizedArtPath = normalizeUrl(song.song_art);
          const signedUrl = await getSignedUrl(normalizedArtPath);
          
          if (signedUrl) {
            // Preload the image
            const img = new Image();
            img.onload = () => {
              setBgImageSrc(signedUrl);
              setImageLoaded(true);
            };
            img.src = signedUrl;
          }
        } catch (error) {
          console.error('Error loading artwork:', {
            error: error.message,
            songId: song.song_id
          });
        }
      };

      loadArtwork();
    }
  }, [song.song_art, song.song_id]);

  // Setup IntersectionObserver for the card
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const currentRef = cardRef.current;

    if (!currentRef) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    observer.observe(currentRef);

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  const togglePlay = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    console.log('Toggle play:', { 
      songId: song.song_id,
      isPlaying: localIsPlaying
    });

    // Only apply the button disable on touch devices
    const button = e.currentTarget;
    const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    if (isTouch) {
      button.disabled = true;
      setTimeout(() => { button.disabled = false; }, 300);
    }

    try {
      setCurrentSongId(song.song_id);

      if (localIsPlaying) {
        if (audioRef.current) {
          audioRef.current.pause();
          setIsPlaying(false);
        }
        return;
      }

      if (!audioSrc) {
        const normalizedSongFile = normalizeUrl(song.song_file_wm);
        const signedAudioUrl = await getSignedUrl(normalizedSongFile);
        
        if (signedAudioUrl) {
          setAudioSrc(signedAudioUrl);
          await new Promise(resolve => setTimeout(resolve, 100));
          
          if (audioRef.current) {
            console.log('Starting playback:', { songId: song.song_id });
            audioRef.current.load();
            await audioRef.current.play();
            setPlayStartTime(Date.now());
            setIsPlaying(true);
          }
        }
      } else {
        if (audioRef.current) {
          console.log('Resuming playback:', { songId: song.song_id });
          await audioRef.current.play();
          setPlayStartTime(Date.now());
          setIsPlaying(true);
        }
      }
    } catch (error) {
      console.error('Playback error:', { 
        error: error.message,
        songId: song.song_id 
      });
      setIsPlaying(false);
      setPlayStartTime(null);
      if (isTouch) button.disabled = false;
    }
  };

  const handleProgressBarClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (!audioRef.current || !progressBarRef.current) {
        console.log('No audio or progress bar reference available');
        return;
    }

    const rect = progressBarRef.current.getBoundingClientRect();
    const clickX = e.clientX - rect.left;
    const percentage = Math.max(0, Math.min(1, clickX / rect.width));
    
    try {
        // If audio isn't ready, load it first and wait
        if (!isAudioReady) {
            const success = await loadAudio();
            if (!success) {
                console.log('Failed to load audio for seeking');
                return;
            }
            
            // Wait a small amount of time for the audio to be fully ready
            await new Promise(resolve => setTimeout(resolve, 100));
        }

        // Double check we have valid audio state
        if (!audioRef.current?.duration || isNaN(audioRef.current.duration)) {
            console.log('Invalid audio state for seeking');
            return;
        }

        const newTime = percentage * audioRef.current.duration;
        
        if (!isNaN(newTime) && isFinite(newTime)) {
            audioRef.current.currentTime = newTime;
            setCurrentTime(newTime);
            setProgress(percentage * 100);

            if (!localIsPlaying) {
                setCurrentSongId(song.song_id);
                await audioRef.current.play();
                setIsPlaying(true);
                setPlayStartTime(Date.now());
            }
        }
    } catch (error) {
        console.error('Error handling progress bar click:', error);
    }
  };

  // Add a click handler for the card link
  const handleCardClick = (e) => {
    // Prevent navigation if we just clicked the progress bar
    if (localStorage.getItem('preventNavigation') === 'true') {
      e.preventDefault();
      localStorage.removeItem('preventNavigation');
    }
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <CardLink 
      to={`/songs/${song.song_id}`} 
      ref={cardRef}
      onClick={handleCardClick}
    >
      <ImageContainer $blurDataUrl={blurDataUrl} $isLoading={!imageLoaded} />
      <MainImage $bgImage={bgImageSrc} $isLoaded={imageLoaded} />
      {ranking && (
        <RankingNumber hidden={hidePlayControls} ranking={ranking}>
          {ranking}
          {ranking === 1 && <HiOutlineFire />}
        </RankingNumber>
      )}
      {isFeatured && (
        <FeaturedBadge hidden={hidePlayControls} isFeatured={isFeatured} ranking={ranking}>
          <HiStar /> Featured
        </FeaturedBadge>
      )}
      {!hidePlayControls && !isRadioCard && (
        <>
          <PlayButton
            $hidePlayControls={hidePlayControls}
            onClick={togglePlay}
            className="play-button"
            aria-label={localIsPlaying ? 'Pause' : 'Play'}
          >
            {localIsPlaying ? <FaPause /> : <FaPlay />}
          </PlayButton>
          <ProgressBarContainer 
            $hidePlayControls={hidePlayControls} 
            onClick={handleProgressBarClick}
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <ProgressBar 
              ref={progressBarRef} 
              $progress={progress}
              onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
            {localIsPlaying && (
              <TimerContainer>
                <TimeText>{formatTime(currentTime)}</TimeText>
                <TimeText>-{formatTime(duration - currentTime)}</TimeText>
              </TimerContainer>
            )}
          </ProgressBarContainer>
        </>
      )}
      <InfoOverlay>
        <h3>{song.song_title}</h3>
        <BpmKeyContainer>
          <BpmKeyPill>{song.song_bpm}</BpmKeyPill>
          <BpmKeyPill>{song.song_key}</BpmKeyPill>
        </BpmKeyContainer>
      </InfoOverlay>
    </CardLink>
  );
});

export default SongCard;