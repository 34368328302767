// src/components/Footer.js
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faBluesky } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { HiRadio } from 'react-icons/hi2';

// Container for the entire footer
const FooterContainer = styled.footer`
  font-family: 'Rubik', sans-serif;
  color: rgba(46, 46, 46, 0.4);
  background-color: #E3E3E3;
  padding: 30px 20px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// Container for the top section (Copyright)
const TopSection = styled.div`
  margin-bottom: 20px;
`;

const CopyrightText = styled.p`
  font-size: 14px;
  margin: 0;
`;

// Container for links and social icons
const MiddleSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media (min-width: 480px) {
    flex-direction: row;
    gap: 40px;
    margin-bottom: 20px;
  }
`;

// Container for Terms and Privacy links
const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  a {
    color: rgba(46, 46, 46, 0.4);
    text-decoration: none;
    margin: 5px 0;
    font-size: 14px;
    transition: color 0.2s;

    &:hover {
      color: rgba(46, 46, 46, 0.6);
    }
    
    @media (min-width: 480px) {
      margin: 0 10px;
    }
  }

  @media (min-width: 480px) {
    flex-direction: row;
  }
`;

// Container for social media icons
const IconsContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  margin-left: 0;

  a {
    color: rgba(46, 46, 46, 0.4);
    font-size: 20px;
    transition: color 0.2s;
    display: flex;
    align-items: center;

    &:hover {
      color: rgba(46, 46, 46, 0.6);
    }
  }

  @media (min-width: 480px) {
    margin-top: 0;
    margin-left: -30px;
  }

  .radio-icon {
    width: 20px;
    height: 20px;
  }
`;

function Footer() {
  return (
    <FooterContainer>
      <TopSection>
        <CopyrightText>
          &copy; 2025 Tempo Stock
        </CopyrightText>
      </TopSection>
      <MiddleSection>
        <LinksContainer>
          <a href="/tos">Terms of Service</a>
          <a href="/privacy">Privacy Policy</a>
        </LinksContainer>
        <IconsContainer>
          <a href="/radio" aria-label="Radio">
            <HiRadio className="radio-icon" />
          </a>
          <a href="https://x.com/tempostockmusic" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
            <FontAwesomeIcon icon={faXTwitter} />
          </a>
          <a href="https://bsky.app/profile/tempostock.com" target="_blank" rel="noopener noreferrer" aria-label="Bluesky">
            <FontAwesomeIcon icon={faBluesky} />
          </a>
          <a href="mailto:hello@tempostock.com" aria-label="Email">
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </IconsContainer>
      </MiddleSection>
    </FooterContainer>
  );
}

export default Footer;