import React, { createContext, useState, useContext } from 'react';
import { getRandomSongs } from './utils/songUtils';

const SongContext = createContext();

export const useSongContext = () => useContext(SongContext);

export const SongProvider = ({ children }) => {
    const [currentSongId, setCurrentSongId] = useState(null);

    const fetchRandomSongs = async (count = 1) => {
        return await getRandomSongs(count);
    };

    return (
        <SongContext.Provider value={{ 
            currentSongId, 
            setCurrentSongId,
            fetchRandomSongs
        }}>
            {children}
        </SongContext.Provider>
    );
};