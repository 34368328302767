import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { FaChartLine } from 'react-icons/fa';
import SongCard from '../components/SongCard';
import TopText from '../components/TopText';
import { getTrendingSongs } from '../utils/songUtils';

const PageContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #E3E3E3;
  min-height: 100vh;
`;

const SongGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 40px;
`;

const LoadingText = styled.div`
  color: #2E2E2E;
  text-align: center;
  padding: 20px;
  font-family: 'Rubik', sans-serif;
`;

function TrendingStockPage() {
  const [trendingSongs, setTrendingSongs] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();
  const SONGS_PER_PAGE = 20;

  const lastSongElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  useEffect(() => {
    const fetchTrendingSongs = async () => {
      setLoading(true);
      try {
        const newSongs = await getTrendingSongs(page, SONGS_PER_PAGE);
        setTrendingSongs(prev => {
          // Filter out duplicates based on song_id
          const uniqueSongs = [...prev, ...newSongs].filter((song, index, self) =>
            index === self.findIndex(s => s.song_id === song.song_id)
          );
          return uniqueSongs;
        });
        setHasMore(newSongs.length === SONGS_PER_PAGE);
      } catch (error) {
        console.error('Error fetching trending songs:', error);
      }
      setLoading(false);
    };

    fetchTrendingSongs();
  }, [page]);

  return (
    <PageContainer>
      <Helmet>
        <title>Trending Stock | Tempo Stock</title>
        <meta name="description" content="Discover the most trending royalty-free music tracks on Tempo Stock." />
        <meta property="og:title" content="Trending Stock | Tempo Stock" />
        <meta property="og:description" content="Discover the most trending royalty-free music tracks on Tempo Stock." />
        <meta name="twitter:title" content="Trending Stock | Tempo Stock" />
        <meta name="twitter:description" content="Discover the most trending royalty-free music tracks on Tempo Stock." />
      </Helmet>

      <TopText icon={FaChartLine} title="Trending Stock" />
      
      <SongGrid>
        {trendingSongs.map((song, index) => {
          if (trendingSongs.length === index + 1) {
            return (
              <div ref={lastSongElementRef} key={song.song_id}>
                <SongCard 
                  song={song} 
                  ranking={index + 1}
                  shouldTrackPlay={false}
                />
              </div>
            );
          } else {
            return (
              <SongCard 
                key={song.song_id} 
                song={song} 
                ranking={index + 1}
                shouldTrackPlay={false}
              />
            );
          }
        })}
      </SongGrid>
      {loading && <LoadingText>Loading more tracks...</LoadingText>}
    </PageContainer>
  );
}

export default TrendingStockPage; 