// src/pages/SearchResultsPage.js

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import SongCard from '../components/SongCard';
import { searchSongs } from '../utils/songUtils';

// Styled Components (unchanged)
const PageContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #E3E3E3;
  width: 100%;
  box-sizing: border-box;
`;

const Title = styled.h1`
  font-family: 'DM Serif Text', serif;
  color: #2E2E2E;
  margin-bottom: 20px;
  text-align: center;
`;

const SongGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;
  width: calc(100% - 40px); /* Account for padding */
  max-width: 1200px;
  margin: 20px auto 0;
  justify-items: center;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 30px;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    gap: 30px;
    width: calc(100% - 20px); /* Smaller padding on mobile */
  }
`;

const EmptyMessage = styled.p`
  font-family: 'Rubik', sans-serif;
  color: #6A7D8B;
  font-size: 18px;
  margin-top: 30px;
  text-align: center;
`;

const Sentinel = styled.div`
  height: 1px;
`;

function SearchResultsPage() {
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('q');
  
  const [allSongs, setAllSongs] = useState([]);
  const [visibleSongs, setVisibleSongs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const songsPerLoad = 8;
  
  const sentinelRef = useRef();
  const observer = useRef();

  // Function to load more songs
  const loadMoreSongs = useCallback(() => {
    setVisibleSongs((prevSongs) => {
      const nextSongs = allSongs.slice(
        prevSongs.length,
        prevSongs.length + songsPerLoad
      );
      return [...prevSongs, ...nextSongs];
    });
  }, [allSongs]);

  // Fetch search results
  useEffect(() => {
    const fetchSongs = async () => {
      try {
        setLoading(true);
        setError(null);
        const results = await searchSongs(query || '');
        setAllSongs(results);
        setVisibleSongs(results.slice(0, songsPerLoad));
      } catch (err) {
        console.error('Error fetching search results:', err);
        setError('Failed to fetch search results. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    fetchSongs();
  }, [query, songsPerLoad]);

  // Setup IntersectionObserver for lazy loading
  useEffect(() => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (
        entries[0].isIntersecting &&
        visibleSongs.length < allSongs.length
      ) {
        loadMoreSongs();
      }
    });

    if (sentinelRef.current) {
      observer.current.observe(sentinelRef.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [loading, visibleSongs, allSongs, loadMoreSongs]);

  return (
    <PageContainer>
      <Title>Search results for "{query}"</Title>
      
      {loading && <EmptyMessage>Loading songs...</EmptyMessage>}
      {error && <EmptyMessage>{error}</EmptyMessage>}

      {!loading && !error && (
        <>
          {visibleSongs.length > 0 ? (
            <>
              <SongGrid>
                {visibleSongs.map((song) => (
                  <SongCard key={song.song_id} song={song} shouldTrackPlay={true} />
                ))}
              </SongGrid>
              {visibleSongs.length < allSongs.length && (
                <>
                  <Sentinel ref={sentinelRef} />
                  <EmptyMessage>Loading more songs...</EmptyMessage>
                </>
              )}
            </>
          ) : (
            <EmptyMessage>No songs found matching your query.</EmptyMessage>
          )}
        </>
      )}
    </PageContainer>
  );
}

export default SearchResultsPage;