import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import { Helmet } from 'react-helmet-async';

const NotFoundContainer = styled.div`
  max-width: 800px;
  margin: 40px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;

  @media (max-width: 768px) {
    margin: 40px 20px;
  }
`;

const Title = styled.h1`
  font-family: 'DM Serif Text', serif;
  color: #2e2e2e;
  margin-bottom: 20px;
  font-size: 2.5em;
`;

const Message = styled.p`
  font-family: 'Rubik', sans-serif;
  color: #555;
  line-height: 1.6;
  margin-bottom: 30px;
  font-size: 1.1em;
`;

const HomeButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background-color: #2e2e2e;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  text-decoration: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: #444;
  }
`;

const NotFoundPage = () => {
  return (
    <NotFoundContainer>
      <Helmet>
        <title>Page Not Found | Tempo Stock</title>
        <meta name="description" content="The page you're looking for cannot be found on Tempo Stock." />
        <meta property="og:title" content="Page Not Found | Tempo Stock" />
        <meta property="og:description" content="The page you're looking for cannot be found on Tempo Stock." />
        <meta name="twitter:title" content="Page Not Found | Tempo Stock" />
        <meta name="twitter:description" content="The page you're looking for cannot be found on Tempo Stock." />
      </Helmet>
      <Title>404 - Page Not Found</Title>
      <Message>
        Oops! The page you're looking for doesn't exist. 
        It might have been moved or deleted.
      </Message>
      <HomeButton to="/">
        <FaHome /> Return Home
      </HomeButton>
    </NotFoundContainer>
  );
};

export default NotFoundPage; 