import {
  FaBriefcase,
  FaSun,
  FaFilm,
  FaLeaf,
  FaPeace,
  FaBolt,
  FaMountain,
  FaGuitar,
  FaSadTear,
  FaCoffee,
  FaHeart,
  FaSmile,
  FaCompactDisc,
  FaCloud,
  FaMicrochip,
  FaFire,
  FaMicrophone,
  FaDrum,
  FaQuestionCircle,
  FaMusic,
  FaGift,
  FaWater
} from 'react-icons/fa';
import { GiAccordion, GiClover, GiMusicalNotes, GiTrumpet, GiMeditation } from 'react-icons/gi';

// Tag Colors Mapping (Muted and Darker Colors)
export const tagColors = {
  Corporate: '#2F4F4F', // Dark Slate Gray
  Uplifting: '#556B2F', // Dark Olive Green
  Cinematic: '#4B0082', // Indigo
  Calm: '#4682B4', // Steel Blue
  Piano: '#6A5ACD', // Slate Blue
  Peaceful: '#2E8B57', // Sea Green
  Energetic: '#B22222', // Firebrick
  Epic: '#800000', // Maroon
  Guitar: '#8B4513', // Saddle Brown
  Sad: '#00008B', // Dark Blue
  Lofi: '#708090', // Slate Gray
  Romantic: '#8B0000', // Dark Red
  Happy: '#DAA520', // Goldenrod
  Pop: '#9932CC', // Dark Orchid
  Vaporwave: '#2F4F4F', // Dark Slate Gray
  Electronic: '#2F4F4F', // Dark Slate Gray
  Angry: '#8B0000', // Dark Red
  'Hip-Hop': '#000000', // Black
  Beats: '#696969', // Dim Gray
  Mysterious: '#4B0082', // Indigo
  Accordion: '#800000', // Maroon
  Celtic: '#556B2F', // Dark Olive Green
  Classical: '#1E90FF', // Dodger Blue
  Holiday: '#FF4500', // Orange Red
  Trumpet: '#8B4513', // Saddle Brown
  Meditation: '#9932CC', // Dark Orchid
  Ambient: '#4682B4', // Steel Blue
};

// Tag Icons Mapping
export const tagIcons = {
  Ambient: FaWater,
  Corporate: FaBriefcase,
  Uplifting: FaSun,
  Cinematic: FaFilm,
  Calm: FaLeaf,
  Piano: FaMusic,
  Peaceful: FaPeace,
  Energetic: FaBolt,
  Epic: FaMountain,
  Guitar: FaGuitar,
  Sad: FaSadTear,
  Lofi: FaCoffee,
  Romantic: FaHeart,
  Happy: FaSmile,
  Pop: FaCompactDisc,
  Vaporwave: FaCloud,
  Electronic: FaMicrochip,
  Angry: FaFire,
  'Hip-Hop': FaMicrophone,
  Beats: FaDrum,
  Mysterious: FaQuestionCircle,
  Accordion: GiAccordion,
  Celtic: GiClover,
  Classical: GiMusicalNotes,
  Holiday: FaGift,
  Trumpet: GiTrumpet,
  Meditation: GiMeditation,
};

// Mood categories with their associated icons and colors
export const moods = [
  { name: 'Epic', icon: FaMountain, color: '#800000' },      // Maroon
  { name: 'Sad', icon: FaSadTear, color: '#00008B' },        // Dark Blue
  { name: 'Uplifting', icon: FaSun, color: '#556B2F' },      // Dark Olive Green
  { name: 'Calm', icon: FaLeaf, color: '#4682B4' },          // Steel Blue
  { name: 'Romantic', icon: FaHeart, color: '#8B0000' },     // Dark Red
  { name: 'Energetic', icon: FaBolt, color: '#B22222' },     // Firebrick
  { name: 'Mysterious', icon: FaQuestionCircle, color: '#4B0082' }, // Indigo
  { name: 'Happy', icon: FaSmile, color: '#DAA520' },        // Goldenrod
  { name: 'Peaceful', icon: FaPeace, color: '#2E8B57' },     // Sea Green
  { name: 'Angry', icon: FaFire, color: '#8B0000' },         // Dark Red
]; 