// src/components/Badge.js
import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { FaCrown, FaGem, FaSeedling } from 'react-icons/fa'; // Import more interesting icons

const shimmer = keyframes`
  0% {
    background-position: -200% center;
  }
  100% {
    background-position: 200% center;
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

// Styled Component for Badge
const StyledBadge = styled.span`
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 25px;
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: default;
  width: fit-content;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;

  /* Common icon styling */
  svg {
    margin-right: 6px;
    font-size: 16px;
    transition: transform 0.3s ease;
  }

  &:hover svg {
    transform: scale(1.2) rotate(5deg);
  }

  /* Conditional Styling Based on Badge Type */
  ${(props) =>
    props.type === 'Free' &&
    css`
      background: linear-gradient(135deg, #4caf50, #45a049);
      color: white;
      border: none;
      
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
          90deg,
          transparent,
          rgba(255, 255, 255, 0.2),
          transparent
        );
        background-size: 200% 100%;
        animation: ${shimmer} 2s infinite;
      }
    `}

  ${(props) =>
    props.type === 'Pro' &&
    css`
      background: linear-gradient(135deg, #2E2E2E, #444);
      color: white;
      border: none;
      animation: ${pulse} 2s infinite ease-in-out;
      
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
          90deg,
          transparent,
          rgba(255, 255, 255, 0.1),
          transparent
        );
        background-size: 200% 100%;
        animation: ${shimmer} 2s infinite;
      }
    `}

  ${(props) =>
    props.type === 'Premier' &&
    css`
      background: linear-gradient(
        45deg,
        #000000,
        #333333,
        #000000
      );
      background-size: 200% 200%;
      color: white;
      border: none;
      position: relative;
      
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
          90deg,
          transparent,
          rgba(255, 255, 255, 0.15),
          transparent
        );
        background-size: 200% 100%;
        animation: ${shimmer} 2s infinite;
      }

      &::after {
        content: '';
        position: absolute;
        inset: -2px;
        background: linear-gradient(45deg, #FFD700, #FFA500, #FFD700);
        background-size: 200% 200%;
        animation: ${shimmer} 3s infinite;
        z-index: -1;
        border-radius: 27px;
      }
    `}

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
`;

// Badge Component
const Badge = ({ type, children }) => {
  // Select icon based on type
  let Icon;
  switch (type) {
    case 'Free':
      Icon = FaSeedling; // Seedling icon represents growth and starting point
      break;
    case 'Pro':
      Icon = FaGem; // Gem icon represents premium value
      break;
    case 'Premier':
      Icon = FaCrown; // Crown icon represents top tier
      break;
    default:
      Icon = FaSeedling;
  }

  return (
    <StyledBadge type={type}>
      <Icon />
      {children}
    </StyledBadge>
  );
};

export default Badge;