// src/components/MoodSection.js
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { moods } from '../constants/tagConfig';

const INITIAL_COLOR = '#2E2E2E';

// Styled Components

const SectionContainer = styled.div`
  margin: 50px 0;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 769px) and (max-width: 1280px) {
    padding: 0 15px;
  }

  @media (max-width: 768px) {
    padding: 0 15px;
  }
`;

const BlocksContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 20px;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 1281px) and (max-width: 1600px) {
    grid-template-columns: repeat(8, 1fr);
    gap: 20px;
  }

  @media (min-width: 769px) and (max-width: 1280px) {
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
`;

// Updated MoodBlock with initial dark grey border and hover effect
const MoodBlock = styled(Link)`
  aspect-ratio: 1;
  width: 100%;
  border: 3px solid ${INITIAL_COLOR};
  border-radius: 20px;
  text-decoration: none;
  color: ${INITIAL_COLOR};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: transform 0.3s ease, border 0.3s ease, color 0.3s ease;

  &:hover {
    transform: scale(1.05);
    border: 3px solid ${(props) => props.color};
    color: ${(props) => props.color};
  }

  @media (min-width: 769px) and (max-width: 1280px) {
    font-size: 0.9em;
  }

  @media (max-width: 768px) {
    font-size: 0.8em;
  }

  @media (max-width: 480px) {
    font-size: 0.7em;
  }
`;

const MoodIconContainer = styled.div`
  position: relative;
  z-index: 1;
  font-size: 2.8rem;
  transition: color 0.3s ease;
  color: ${INITIAL_COLOR};

  ${MoodBlock}:hover & {
    color: ${(props) => props.color};
  }

  svg {
    width: 1em;
    height: 1em;
  }

  @media (min-width: 769px) and (max-width: 1280px) {
    font-size: 2.8rem;
  }

  @media (max-width: 768px) {
    font-size: 3.2rem;
  }

  @media (max-width: 480px) {
    font-size: 3rem;
  }
`;

const MoodText = styled.span`
  position: absolute;
  bottom: 15px;
  font-size: 16px;
  color: ${INITIAL_COLOR};
  font-weight: bold;
  transition: color 0.3s ease;

  ${MoodBlock}:hover & {
    color: ${(props) => props.color};
  }

  @media (min-width: 769px) and (max-width: 1280px) {
    font-size: 14px;
    bottom: 12px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    bottom: 10px;
  }

  @media (max-width: 480px) {
    font-size: 11px;
    bottom: 8px;
  }
`;

const SectionTitle = styled.h2`
  font-family: 'DM Serif Text', serif;
  color: #2E2E2E;
  margin-bottom: 30px;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

// MoodSection Component
function MoodSection() {
  return (
    <SectionContainer>
      <SectionTitle>Browse by mood</SectionTitle>
      <BlocksContainer>
        {moods.map((mood) => {
          const IconComponent = mood.icon;
          return (
            <MoodBlock to={`/${mood.name.toLowerCase()}`} key={mood.name} color={mood.color}>
              <MoodIconContainer color={mood.color}>
                <IconComponent />
              </MoodIconContainer>
              <MoodText color={mood.color}>{mood.name}</MoodText>
            </MoodBlock>
          );
        })}
      </BlocksContainer>
    </SectionContainer>
  );
}

export default MoodSection;