// src/pages/HomePage.js
import React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import SearchBar from '../components/SearchBar';
import CategorySection from '../components/CategorySection';
import MoodSection from '../components/MoodSection';
import TrendingSection from '../components/TrendingSection';

const PageContainer = styled.div`
  max-width: 100%;
  overflow-x: hidden;
`;

const ContentContainer = styled.div`
  width: 75%;
  margin: 0 auto;
  max-width: 100%;
  box-sizing: border-box;
  
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const PageTitle = styled.h1`
  text-align: center;
  margin-top: 70px;
  padding: 0 15px;
  box-sizing: border-box;
`;

function HomePage() {
  return (
    <PageContainer>
      <Helmet>
        <title>Tempo Stock | Royalty Free Music</title>
        <meta name="description" content="Find high quality royalty-free music for your projects on Tempo Stock." />
        <meta property="og:title" content="Tempo Stock | Royalty Free Music" />
        <meta property="og:description" content="Find high quality royalty-free music for your projects on Tempo Stock." />
        <meta name="twitter:title" content="Tempo Stock | Royalty Free Music" />
        <meta name="twitter:description" content="Find high quality royalty-free music for your projects on Tempo Stock." />
      </Helmet>
      <PageTitle>
        Find high quality stock music
      </PageTitle>
      <ContentContainer>
        <SearchBar />
        <TrendingSection />
        <CategorySection />
        <MoodSection />
      </ContentContainer>
    </PageContainer>
  );
}

export default HomePage;