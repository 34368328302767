import React, { useState, useRef, useEffect, useImperativeHandle } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { getSignedUrl } from '../utils/getSignedUrl';

const CardLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: block;
  width: 100%;
  height: 100%;
`;

const CardContainer = styled.div`
  width: 100%;
  aspect-ratio: 0.714;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  background: #2E2E2E;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  min-height: 350px;
  
  @media (max-width: 600px) {
    aspect-ratio: 1;
    min-height: unset;
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2E2E2E;
  transition: opacity 0.3s ease-out;
  opacity: ${props => props.$isLoading ? 0.5 : 1};
`;

const MainImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: ${props => props.$bgImage ? `url(${props.$bgImage})` : 'none'};
  background-size: cover;
  background-position: center;
  opacity: ${props => props.$isLoaded ? 1 : 0};
  transition: opacity 0.3s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(46, 46, 46, 0.4);
    z-index: 0;
  }
`;

const InfoOverlay = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 15px 10px; 
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);

  @media (max-width: 768px) {
    padding: 20px 15px;  // More padding on mobile for better touch targets
  }

  h3 {
    margin: 5px 0;
    color: white;
    text-align: center;
    max-width: 90%;
    font-size: 1.1em;

    @media (max-width: 768px) {
      font-size: 1em;  // Slightly smaller font on mobile
      margin: 3px 0;
    }
  }
`;

const BpmKeyContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 5px;

  @media (max-width: 768px) {
    margin-top: 3px;  // Less margin on mobile
  }
`;

const BpmKeyPill = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 8px;
  color: #D8D8D8;
  font-size: 0.8em;
`;

const RadioCard = React.forwardRef(({ 
  song, 
  isPlaying, 
  onEnded,
  hidePlayControls = false
}, ref) => {
  const [bgImageSrc, setBgImageSrc] = useState('');
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isAudioReady, setIsAudioReady] = useState(false);
  const audioRef = useRef(null);
  const isUnmounting = useRef(false);

  // Initialize audio element
  useEffect(() => {
    if (!audioRef.current) {
      const audio = new Audio();
      audio.preload = 'auto';
      audioRef.current = audio;
    }

    const audio = audioRef.current;
    const handleLoadedMetadata = () => {
      if (!isUnmounting.current) {
        setIsAudioReady(true);
      }
    };

    const handleEnded = () => {
      if (!isUnmounting.current && onEnded) {
        onEnded();
      }
    };

    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio.addEventListener('ended', handleEnded);

    // Load audio source
    if (song.song_file_wm) {
      const loadAudio = async () => {
        try {
          const signedUrl = await getSignedUrl(song.song_file_wm);
          if (signedUrl && audioRef.current && !isUnmounting.current) {
            // Store current time and playing state
            const wasPlaying = !audio.paused;
            const currentTime = audio.currentTime;
            
            // Update source
            audioRef.current.src = signedUrl;
            await audioRef.current.load();
            
            // Restore state if needed
            if (wasPlaying && isPlaying) {
              try {
                await audioRef.current.play();
              } catch (error) {
                console.error('Error resuming playback:', error);
              }
            }
            audioRef.current.currentTime = currentTime;
          }
        } catch (error) {
          console.error('Error loading audio:', error);
        }
      };
      loadAudio();
    }

    return () => {
      isUnmounting.current = true;
      // Don't clear the audio element, just remove listeners
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.removeEventListener('ended', handleEnded);
    };
  }, [song.song_file_wm, onEnded, isPlaying]);

  // Handle play state
  useEffect(() => {
    if (!audioRef.current || isUnmounting.current) return;

    const playAudio = async () => {
      if (isPlaying) {
        try {
          // Ensure audio is loaded before playing
          if (!isAudioReady) {
            await new Promise((resolve) => {
              const checkReady = () => {
                if (isAudioReady || isUnmounting.current) {
                  resolve();
                } else {
                  setTimeout(checkReady, 100);
                }
              };
              checkReady();
            });
          }
          
          if (!isUnmounting.current) {
            await audioRef.current.play();
          }
        } catch (error) {
          console.error('Playback error:', error);
        }
      } else {
        audioRef.current.pause();
      }
    };

    playAudio();
  }, [isPlaying, isAudioReady]);

  // Load artwork
  useEffect(() => {
    const loadArtwork = async () => {
      if (!song.song_art) return;
      
      try {
        const signedUrl = await getSignedUrl(song.song_art);
        if (signedUrl) {
          const img = new Image();
          img.onload = () => {
            setBgImageSrc(signedUrl);
            setImageLoaded(true);
          };
          img.src = signedUrl;
        }
      } catch (error) {
        console.error('Error loading artwork:', error);
      }
    };

    loadArtwork();
  }, [song.song_art]);

  // Load artwork and audio
  useEffect(() => {
    let isMounted = true;
    
    const loadAudio = async () => {
      if (!song.song_file_wm) return;
      
      try {
        const signedUrl = await getSignedUrl(song.song_file_wm);
        if (signedUrl && audioRef.current && isMounted) {
          audioRef.current.src = signedUrl;
          await audioRef.current.load();
        }
      } catch (error) {
        console.error('Error loading audio:', error);
      }
    };

    loadAudio();

    return () => {
      isMounted = false;
    };
  }, [song.song_file_wm]);

  // Expose methods via ref
  useImperativeHandle(ref, () => ({
    audioRef,
    play: async () => {
      if (audioRef.current) {
        try {
          await audioRef.current.play();
        } catch (error) {
          console.error('Error playing audio:', error);
        }
      }
    },
    pause: () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    },
    get currentTime() {
      return audioRef.current?.currentTime || 0;
    },
    set currentTime(value) {
      if (audioRef.current) {
        audioRef.current.currentTime = value;
      }
    },
    get duration() {
      return audioRef.current?.duration || 0;
    },
    get isReady() {
      return isAudioReady;
    },
    addEventListener: (event, handler) => {
      if (audioRef.current) {
        audioRef.current.addEventListener(event, handler);
      }
    },
    removeEventListener: (event, handler) => {
      if (audioRef.current) {
        audioRef.current.removeEventListener(event, handler);
      }
    }
  }), [isAudioReady]);

  const handleCardClick = (e) => {
    // If we're playing, pause the audio before navigating
    if (isPlaying && audioRef.current) {
      audioRef.current.pause();
    }
    
    // If we clicked on a control element (like progress bar), prevent navigation
    if (localStorage.getItem('preventNavigation') === 'true') {
      e.preventDefault();
      localStorage.removeItem('preventNavigation');
    }
  };

  return (
    <CardLink to={`/songs/${song.song_id}`} onClick={handleCardClick}>
      <CardContainer>
        <ImageContainer $isLoading={!imageLoaded}>
          <MainImage $bgImage={bgImageSrc} $isLoaded={imageLoaded} />
        </ImageContainer>
        <InfoOverlay>
          <h3>{song.song_title}</h3>
          <BpmKeyContainer>
            <BpmKeyPill>{song.song_bpm}</BpmKeyPill>
            <BpmKeyPill>{song.song_key}</BpmKeyPill>
          </BpmKeyContainer>
        </InfoOverlay>
      </CardContainer>
    </CardLink>
  );
});

export default RadioCard; 