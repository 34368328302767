import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaPlay, FaPause, FaVolumeUp, FaVolumeMute, FaExclamationCircle } from 'react-icons/fa';

const PlayerContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  z-index: 2;
  border-radius: 0 0 15px 15px;
`;

const PlayButton = styled.button`
  background: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
  flex-shrink: 0;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }

  /* Increase touch target size on mobile */
  @media (max-width: 768px) {
    width: 48px;
    height: 48px;
  }

  svg {
    font-size: 16px;
  }
`;

const ProgressSection = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ProgressContainer = styled.div`
  width: 100%;
  height: 4px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  cursor: pointer;
  position: relative;

  &:hover {
    height: 5px;
  }
`;

const Progress = styled.div`
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 2px;
  width: ${props => props.$progress}%;
  transition: width 0.1s linear;
`;

const TimeDisplay = styled.div`
  display: flex;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.8);
  font-family: 'Rubik', sans-serif;
  font-size: 0.8em;
  margin-top: 6px;
`;

const VolumeButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: relative;

  &:hover {
    color: rgba(255, 255, 255, 0.8);
  }

  svg {
    font-size: 20px;
  }
`;

const VolumePopup = styled.div`
  position: absolute;
  bottom: 100%;
  right: -48px;
  background: rgba(0, 0, 0, 0.8);
  padding: 10px 14px;
  border-radius: 8px;
  display: ${props => props.$isVisible ? 'block' : 'none'};
  margin-bottom: 10px;
  width: 140px;

  &::after {
    content: '';
    position: absolute;
    bottom: -6px;
    right: 48px;
    width: 12px;
    height: 12px;
    background: rgba(0, 0, 0, 0.8);
    transform: rotate(45deg);
  }
`;

const VolumeSlider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.2);
  outline: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    border: none;
  }
`;

const ErrorOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 15px;
  gap: 10px;
  z-index: 3;
`;

const LoadingOverlay = styled(ErrorOverlay)`
  background: rgba(0, 0, 0, 0.5);
`;

const LoadingSpinner = styled.div`
  width: 24px;
  height: 24px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const trackPlay = async (songId, playDuration) => {
  try {
    const token = localStorage.getItem('token');
    const url = `${process.env.REACT_APP_API_BASE_URL}/songs/${songId}/play`;
    console.log('Tracking play:', { url, songId, playDuration });

    const headers = {
      'Content-Type': 'application/json'
    };

    // Add Authorization header only if token exists
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify({ playDuration })
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Play tracking failed:', {
        status: response.status,
        statusText: response.statusText,
        errorText,
        headers: Object.fromEntries(response.headers.entries())
      });
      return;
    }

    const data = await response.json();
    console.log('Play tracked successfully:', data);
  } catch (error) {
    console.error('Error tracking play:', {
      error: error.message,
      stack: error.stack,
      songId,
      playDuration
    });
  }
};

const CustomAudioPlayer = ({ audioUrl, isPlaying: externalIsPlaying, onPlayingChange, songId }) => {
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const [showVolumePopup, setShowVolumePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [playStartTime, setPlayStartTime] = useState(null);
  const audioRef = React.useRef(null);
  const handlersRef = React.useRef(null);

  // Store the latest values in refs so our handlers can access them
  const latestPropsRef = React.useRef({
    onPlayingChange,
    songId,
    volume,
    playStartTime
  });

  // Keep the refs updated with latest values
  useEffect(() => {
    latestPropsRef.current = {
      onPlayingChange,
      songId,
      volume,
      playStartTime
    };
  });

  // Track plays when playback stops (pause, navigation, refresh)
  useEffect(() => {
    if (!externalIsPlaying && playStartTime) {
      const playDuration = Math.floor((Date.now() - playStartTime) / 1000);
      if (playDuration >= 5) {
        trackPlay(songId, playDuration);
      }
      setPlayStartTime(null);
    }
  }, [externalIsPlaying, playStartTime, songId]);

  // Handle play state changes
  useEffect(() => {
    if (!audioRef.current || error) return;

    const handlePlay = async () => {
      try {
        await audioRef.current.play();
        setPlayStartTime(Date.now());
      } catch (err) {
        setError('Failed to play audio. Please try again.');
        onPlayingChange(false);
      }
    };

    if (externalIsPlaying) {
      handlePlay();
    } else {
      audioRef.current.pause();
      setPlayStartTime(null);
    }
  }, [externalIsPlaying, error, onPlayingChange]);

  // Initialize audio element and event handlers
  useEffect(() => {
    const audio = new Audio();
    audioRef.current = audio;
    audio.volume = latestPropsRef.current.volume;

    // Store handlers in a ref to avoid recreating them
    handlersRef.current = {
      handleLoadStart: () => {
        setIsLoading(true);
        setError(null);
      },
      handleCanPlay: () => {
        setIsLoading(false);
        setDuration(audio.duration);
      },
      handleError: () => {
        setIsLoading(false);
        setError('Failed to load audio. Please try again later.');
        latestPropsRef.current.onPlayingChange(false);
        setPlayStartTime(null);
      },
      handleTimeUpdate: () => {
        setCurrentTime(audio.currentTime);
        setProgress((audio.currentTime / audio.duration) * 100);
      },
      handleEnded: async () => {
        const { playStartTime, songId, onPlayingChange } = latestPropsRef.current;
        if (playStartTime) {
          const playDuration = Math.floor((Date.now() - playStartTime) / 1000);
          if (playDuration >= 5) {
            await trackPlay(songId, playDuration);
          }
        }
        onPlayingChange(false);
        setProgress(0);
        setCurrentTime(0);
        setPlayStartTime(null);
      }
    };

    // Attach event listeners
    audio.addEventListener('loadstart', handlersRef.current.handleLoadStart);
    audio.addEventListener('canplay', handlersRef.current.handleCanPlay);
    audio.addEventListener('error', handlersRef.current.handleError);
    audio.addEventListener('timeupdate', handlersRef.current.handleTimeUpdate);
    audio.addEventListener('ended', handlersRef.current.handleEnded);

    return () => {
      const { playStartTime, songId } = latestPropsRef.current;
      if (playStartTime) {
        const playDuration = Math.floor((Date.now() - playStartTime) / 1000);
        if (playDuration >= 5) {
          trackPlay(songId, playDuration);
        }
      }
      audio.pause();
      audio.src = '';
      audio.removeEventListener('loadstart', handlersRef.current.handleLoadStart);
      audio.removeEventListener('canplay', handlersRef.current.handleCanPlay);
      audio.removeEventListener('error', handlersRef.current.handleError);
      audio.removeEventListener('timeupdate', handlersRef.current.handleTimeUpdate);
      audio.removeEventListener('ended', handlersRef.current.handleEnded);
      audioRef.current = null;
    };
  }, []); // Now we can safely use empty deps because we access latest values via ref

  // Update volume when it changes
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  // Handle audio URL changes
  useEffect(() => {
    if (!audioRef.current) return;
    audioRef.current.src = audioUrl;
    audioRef.current.load();
  }, [audioUrl]);

  const togglePlay = () => {
    if (error) return;
    onPlayingChange(!externalIsPlaying);
  };

  const handleProgressClick = (e) => {
    if (error) return;
    
    const container = e.currentTarget;
    const rect = container.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const percentage = (x / rect.width) * 100;
    const newTime = (percentage / 100) * duration;
    
    audioRef.current.currentTime = newTime;
    setProgress(percentage);
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    audioRef.current.volume = newVolume;
    setIsMuted(newVolume === 0);
  };

  const toggleMute = () => {
    if (isMuted) {
      audioRef.current.volume = volume;
      setIsMuted(false);
    } else {
      audioRef.current.volume = 0;
      setIsMuted(true);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <PlayerContainer className="player-controls">
      {isLoading && (
        <LoadingOverlay>
          <LoadingSpinner />
        </LoadingOverlay>
      )}
      {error && (
        <ErrorOverlay>
          <FaExclamationCircle />
          {error}
        </ErrorOverlay>
      )}
      <PlayButton 
        onClick={togglePlay}
        disabled={isLoading || error}
        aria-label={externalIsPlaying ? 'Pause' : 'Play'}
        aria-disabled={isLoading || error}
      >
        {externalIsPlaying ? <FaPause /> : <FaPlay />}
      </PlayButton>
      <ProgressSection>
        <ProgressContainer 
          onClick={handleProgressClick}
          role="slider"
          aria-label="Audio progress"
          aria-valuemin="0"
          aria-valuemax="100"
          aria-valuenow={progress}
        >
          <Progress $progress={progress} />
        </ProgressContainer>
        <TimeDisplay>
          <span>{formatTime(currentTime)}</span>
          <span>{formatTime(duration)}</span>
        </TimeDisplay>
      </ProgressSection>
      <VolumeButton 
        onClick={toggleMute}
        onMouseEnter={() => setShowVolumePopup(true)}
        onMouseLeave={() => setShowVolumePopup(false)}
        aria-label={isMuted ? 'Unmute' : 'Mute'}
      >
        {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
        <VolumePopup $isVisible={showVolumePopup}>
          <VolumeSlider
            type="range"
            min="0"
            max="1"
            step="0.1"
            value={isMuted ? 0 : volume}
            onChange={handleVolumeChange}
            aria-label="Volume"
          />
        </VolumePopup>
      </VolumeButton>
    </PlayerContainer>
  );
};

export default CustomAudioPlayer; 