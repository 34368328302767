import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 769px) and (max-width: 1280px) {
    padding: 0 15px;
  }

  @media (max-width: 768px) {
    padding: 0 15px;
  }
`;

const SearchInput = styled.input`
  width: 100%; 
  padding: 15px;
  font-size: 16px;
  border-radius: 15px;
  border: 3px solid #2E2E2E;
  background-color: white;
  color: #2E2E2E;
  outline: none;
  box-sizing: border-box;
  transition: border-color 0.3s;
  max-width: 100%;

  &:focus {
    border-color: #6A7D8B;
  }

  @media (max-width: 768px) {
    &::placeholder {
      text-align: center;
    }
  }
`;

function SearchBar() {
    const [query, setQuery] = useState('');
    const [placeholder, setPlaceholder] = useState('Search for songs by name, tags, artists...');
    const navigate = useNavigate();
  
    useEffect(() => {
      const updatePlaceholder = () => {
        if (window.matchMedia('(max-width: 768px)').matches) {
          setPlaceholder('Ambient, energetic...');
        } else {
          setPlaceholder('Search for songs by name, tags, artists...');
        }
      };
  
      updatePlaceholder(); // Run on mount
      window.addEventListener('resize', updatePlaceholder); // Listen for window resize
  
      return () => {
        window.removeEventListener('resize', updatePlaceholder);
      };
    }, []);
  
    const handleSearch = (e) => {
      if (e.key === 'Enter') {
        navigate(`/search?q=${encodeURIComponent(query)}`);
      }
    };
  
    return (
      <SearchContainer>
        <SearchInput
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={handleSearch}
          placeholder={placeholder}
        />
      </SearchContainer>
    );
  }
  
  export default SearchBar;