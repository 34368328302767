import React, { useEffect, useContext } from 'react';
import Intercom from '@intercom/messenger-js-sdk';
import { AuthContext } from '../context/AuthContext';

// For local development only
const generateLocalHMAC = async (userId) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(userId.toString());
  const keyData = encoder.encode('KMe30Gh2HAadJTnyQYsIDw4XlO5CxeclywYdjubB');
  
  const hashBuffer = await crypto.subtle.importKey(
    'raw',
    keyData,
    { name: 'HMAC', hash: 'SHA-256' },
    false,
    ['sign']
  ).then(key => crypto.subtle.sign(
    'HMAC',
    key,
    data
  ));

  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
};

export const IntercomProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const isLocalhost = window.location.hostname === 'localhost';

  useEffect(() => {
    const initializeIntercom = async () => {
      if (user) {
        let hash;
        
        if (isLocalhost) {
          // Generate hash locally for development
          hash = await generateLocalHMAC(user.id);
        } else {
          // Get HMAC from production endpoint
          const res = await fetch(`${API_BASE_URL}/auth/intercom-hash`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({ userId: user.id }),
          });
          const data = await res.json();
          hash = data.hash;
        }

        // Initialize Intercom with user data and hash
        Intercom({
          app_id: 'uktdfbhz',
          user_id: user.id,
          name: user.name,
          email: user.email,
          created_at: Math.floor(new Date(user.createdAt).getTime() / 1000),
          user_hash: hash,
        });
      } else {
        // Initialize Intercom for anonymous users
        Intercom({
          app_id: 'uktdfbhz',
        });
      }
    };

    initializeIntercom();
  }, [user, API_BASE_URL, isLocalhost]); // Re-initialize when user data changes

  return <>{children}</>;
}; 