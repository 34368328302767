import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SongCard from './SongCard';
import { API_BASE_URL } from '../utils/songUtils';

const SectionContainer = styled.div`
  margin: 50px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  @media (min-width: 769px) and (max-width: 1280px) {
    padding: 0 15px;
  }

  @media (max-width: 768px) {
    padding: 0 15px;
  }
`;

const SongsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 769px) and (max-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
`;

const SectionTitle = styled.h2`
  font-family: 'DM Serif Text', serif;
  color: #2E2E2E;
  margin-bottom: 30px;
  text-align: left;
  width: 100%;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

function TrendingSection() {
  const [trendingSongs, setTrendingSongs] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchTrendingSongs = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/songs/trending`);
        if (response.ok) {
          const songs = await response.json();
          // Show different number of songs based on screen size
          let numSongs;
          if (windowWidth <= 768) {
            numSongs = 4; // mobile
          } else if (windowWidth <= 1280) {
            numSongs = 6; // tablet/middle size
          } else {
            numSongs = 5; // desktop
          }
          setTrendingSongs(songs.slice(0, numSongs));
        }
      } catch (error) {
        console.error('Error fetching trending songs:', error);
      }
    };
    fetchTrendingSongs();
  }, [windowWidth]);

  return (
    <SectionContainer>
      <SectionTitle>Trending stock</SectionTitle>
      <SongsContainer>
        {trendingSongs.map((song, index) => (
          <SongCard 
            key={song.song_id} 
            song={song}
            ranking={index + 1}
            hidePlayControls={true}
          />
        ))}
      </SongsContainer>
    </SectionContainer>
  );
}

export default TrendingSection; 