// src/pages/UserPage.js
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { MdAccountCircle } from 'react-icons/md'; // Importing an account icon
import Badge from '../components/Badge'; // Import the reusable Badge component
import TopText from '../components/TopText';
import { Helmet } from 'react-helmet-async';

// Styled component for the User Information Container
const UserContainer = styled.div`
  max-width: 500px;
  margin: 0 auto; /* Center the container */
  padding: 40px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  font-family: 'Rubik', sans-serif;
  position: relative; /* To position the FreeBadge absolutely if needed */

  @media (max-width: 480px) {
    padding: 20px;
    margin: 30px;
  }
`;

// Styled component for the Account Details Header
const AccountDetailsHeader = styled.h3`
  font-size: 20px;
  margin-top: 20px; /* Added margin-top to reduce space above */
  margin-bottom: 20px;
  color: #2e2e2e;
  font-family: 'Rubik', sans-serif;
`;

// Styled component for the Email and Badge Container
const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Allows items to wrap on smaller screens */

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

// Styled component for individual detail sections
const DetailSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Reduced margin */

  h3 {
    font-size: 18px;
    margin-right: 10px;
    color: #2e2e2e;
    font-family: 'Rubik', sans-serif;
  }

  p {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin: 0;
    color: #333;
    font-family: 'Rubik', sans-serif;
    gap: 15px; /* Added gap between email and badge */
  }

  /* Optional: Adjust spacing between email and badge */
  .badge {
    /* Removed margin-left and using gap instead */
    /* margin-left: 15px; */
  }
`;

// Styled component for the Reset Form
const ResetForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  h3 {
    font-size: 20px;
    margin-bottom: 15px;
    color: #2e2e2e;
    font-family: 'Rubik', sans-serif;
  }

  label {
    margin-bottom: 8px;
    font-size: 16px;
    color: #333;
    font-family: 'Rubik', sans-serif;
  }

  input[type='password'] {
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 16px;
    font-family: 'Rubik', sans-serif;
    transition: border-color 0.3s;

    &:focus {
      border-color: #2e2e2e;
      outline: none;
    }
  }

  button {
    padding: 12px;
    background-color: #2e2e2e;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Rubik', sans-serif;
    transition: background-color 0.3s;

    &:hover {
      background-color: #444;
    }

    &:disabled {
      background-color: #555;
      cursor: not-allowed;
    }
  }

  p {
    color: #388e3c; /* Green */
    font-size: 14px;
    margin-bottom: 15px;
    font-family: 'Rubik', sans-serif;
  }

  .error {
    color: #d32f2f; /* Red */
    font-size: 14px;
    margin-bottom: 15px;
    font-family: 'Rubik', sans-serif;
  }
`;

// Styled component for the Logout Button
const LogoutButton = styled.button`
  padding: 12px;
  background-color: #f44336; /* Red */
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  transition: background-color 0.3s;
  width: 100%;

  &:hover {
    background-color: #d32f2f;
  }
`;

const UserPage = () => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  if (!user) {
    return (
      <p style={{ textAlign: 'center', fontFamily: 'Rubik, sans-serif', color: '#333' }}>
        Loading...
      </p>
    );
  }

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    setLoading(true);

    const token = localStorage.getItem('token');
    if (!token) {
      setError('Unauthorized');
      setLoading(false);
      return;
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Optional: If you secure this endpoint
        },
        body: JSON.stringify({ email: user.email, newPassword }),
      });

      if (res.ok) {
        setMessage('Password updated successfully.');
        setNewPassword('');
      } else {
        const errorData = await res.json();
        setError(errorData.error || 'Failed to update password.');
      }
    } catch (err) {
      setError('An error occurred while updating your password.');
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <>
      <Helmet>
        <title>My Account | Tempo Stock</title>
        <meta name="description" content="Manage your Tempo Stock account settings and preferences." />
        <meta property="og:title" content="My Account | Tempo Stock" />
        <meta property="og:description" content="Manage your Tempo Stock account settings and preferences." />
        <meta name="twitter:title" content="My Account | Tempo Stock" />
        <meta name="twitter:description" content="Manage your Tempo Stock account settings and preferences." />
      </Helmet>
      
      {/* Header Section with Icon and Title */}
      <TopText icon={MdAccountCircle} title="My Account" />

      {/* User Information Container */}
      <UserContainer>
        {/* Account Details Header */}
        <AccountDetailsHeader>Account Details</AccountDetailsHeader>

        {/* Email and Badge Sections */}
        <DetailsContainer>
          {/* Email Section with Badge */}
          <DetailSection>
            <h3>Email:</h3>
            <p>
              {user.email}
              <Badge className="badge" type={user.account_type}>
                {user.account_type}
              </Badge>
            </p>
          </DetailSection>
        </DetailsContainer>

        {/* Reset Password Form */}
        <ResetForm onSubmit={handlePasswordReset}>
          <h3>Change Password</h3>
          {message && <p>{message}</p>}
          {error && <p className="error">{error}</p>}
          <label htmlFor="new-password">New Password:</label>
          <input
            type="password"
            id="new-password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            placeholder="Enter new password"
          />
          <button type="submit" disabled={loading}>
            {loading ? 'Updating...' : 'Update Password'}
          </button>
        </ResetForm>

        {/* Logout Button */}
        <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
      </UserContainer>
    </>
  );
};

export default UserPage;