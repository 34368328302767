import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FaPlay, FaPause, FaStepBackward, FaStepForward } from 'react-icons/fa';
import RadioCard from './RadioCard';
import { useSongContext } from '../SongContext';
import { getSignedUrl } from '../utils/getSignedUrl';

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  padding-top: 0;
  background: #1A1A1A;

  @media (max-width: 768px) {
    height: 360px;
    padding-top: 0;
  }
`;

const CarouselTrack = styled.div`
  position: absolute;
  left: 50%;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 0.5s ease;
  transform: translateX(-50%);
  z-index: 1;

  @media (max-width: 768px) {
    gap: 8px;  // Slightly reduce gap on mobile
  }
`;

const CarouselSlot = styled.div`
  flex-shrink: 0;
  width: 300px;
  height: 420px;
  transition: all 0.5s ease;
  opacity: ${props => props.$active ? 1 : 0.3};
  transform: ${props => {
    if (props.$active) return 'scale(1)';
    if (props.$position === 'left') return 'scale(0.8) translateX(10%)';
    if (props.$position === 'right') return 'scale(0.8) translateX(-10%)';
    return 'scale(0.8)';
  }};

  @media (max-width: 768px) {
    width: 280px;  // Slightly smaller on mobile
    height: 280px;  // Square aspect ratio on mobile
  }
`;

const AnimatedRow = styled.div`
  position: absolute;
  display: flex;
  gap: 20px;
  animation: scroll linear infinite;
  animation-duration: ${props => props.$duration}s;
  animation-direction: ${props => props.$direction};
  opacity: ${props => props.$opacity || 1};
  left: calc(50% - ${props => props.$itemWidth || 100}px);  // Center the row
  width: max-content;
  padding: 0;
  top: ${props => props.$top}px;
  will-change: transform;
  backface-visibility: hidden;
  transform: translateZ(0);

  @media (max-width: 768px) {
    top: ${props => Math.floor(props.$top * 0.7)}px;
    gap: 15px;
    left: calc(50% - ${props => (props.$itemWidth || 80) / 2}px);  // Adjust for mobile
  }

  @keyframes scroll {
    0% { transform: translateX(0); }
    100% { transform: translateX(calc(-50% - ${props => props.$gap || 20}px)); }
  }
`;

const RowsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transform: translateZ(0);

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 25vw;
    z-index: 2;
    pointer-events: none;
    will-change: opacity;
  }

  &::before {
    left: 0;
    background: linear-gradient(to right, 
      #1A1A1A 0%,
      #1A1A1A 30%,
      rgba(26, 26, 26, 0) 100%
    );
  }

  &::after {
    right: 0;
    background: linear-gradient(to left, 
      #1A1A1A 0%,
      #1A1A1A 30%,
      rgba(26, 26, 26, 0) 100%
    );
  }

  @media (max-width: 768px) {
    &::before,
    &::after {
      width: 20vw;
    }
  }
`;

const AlbumArt = styled.div`
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  background-image: url(${props => props.$imageUrl || '/default-album-art.jpg'});
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  opacity: ${props => props.$opacity || 1};
  will-change: opacity;
  backface-visibility: hidden;
  transform: translateZ(0);

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;

const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 10;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.2);

  svg {
    width: 30px;
    height: 30px;
    color: #1A1A1A;
    margin-left: 5px;
  }

  &:hover {
    transform: translate(-50%, -50%) scale(1.1);
    background: white;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.3);
  }
`;

const StartListeningCard = styled.div`
  position: relative;
  width: 100%;
  height: 420px;
  background: #1A1A1A;
  overflow: hidden;
  cursor: pointer;
  transform: translateZ(0);

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at center, rgba(26, 26, 26, 0) 0%, rgba(26, 26, 26, 0.6) 100%);
    pointer-events: none;
    z-index: 1;
    will-change: opacity;
  }

  @media (max-width: 768px) {
    height: 280px;
  }

  &:hover {
    ${PlayButton} {
      transform: translate(-50%, -50%) scale(1.1);
      background: white;
      box-shadow: 0 0 30px rgba(255, 255, 255, 0.3);
    }
  }
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0 24px;
    margin-top: 0;
    gap: 12px;
  }
`;

const ProgressContainer = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 0;
  margin-bottom: 10px;
  cursor: pointer;
  box-sizing: border-box;

  /* Make sure the click target is large enough */
  padding-top: 10px;
  padding-bottom: 10px;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-top: -10px;
    margin-bottom: 8px;
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 2.5px;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    height: 100%;
    width: ${(props) => props.$progress}%;
    background: white;
    transition: width 0.1s linear;
  }

  /* Add hover effect */
  &:hover {
    height: 7px;
    background: rgba(255, 255, 255, 0.6);
  }
`;

const TimerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`;

const TimeText = styled.div`
  color: #D8D8D8;
  font-size: 0.8em;
  padding: 0 2px;
`;

const ControlButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const ControlButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const StartListeningCardContent = ({ onClick, songs = [] }) => {
  // Filter out songs without album art and ensure we have enough songs
  const validSongs = React.useMemo(() => {
    const filtered = songs.filter(song => song?.song_art);
    // If we don't have enough songs, duplicate what we have
    if (filtered.length < 15) {
      return [...filtered, ...filtered].slice(0, 15);
    }
    return filtered.slice(0, 15);
  }, [songs]);
  
  const [signedUrls, setSignedUrls] = useState({});
  
  useEffect(() => {
    const loadArtwork = async () => {
      const urlPromises = validSongs.map(song => 
        song.song_art ? getSignedUrl(song.song_art, 60) : Promise.resolve(null)
      );
      
      for (let i = 0; i < urlPromises.length; i++) {
        const song = validSongs[i];
        if (song.song_art) {
          urlPromises[i].then(signedUrl => {
            if (signedUrl) {
              setSignedUrls(prev => ({
                ...prev,
                [song.song_id]: signedUrl
              }));
            }
          }).catch(error => {
            console.error('Error loading artwork:', error);
          });
        }
      }
    };

    if (validSongs.length > 0) {
      loadArtwork();
    }
  }, [validSongs]);

  // Create unique sequences for each row
  const rowSequences = React.useMemo(() => {
    const shuffle = arr => {
      const array = [...arr];
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };

    // Shuffle all songs first
    const shuffledSongs = shuffle([...validSongs]);
    
    // Split into three equal groups
    const chunkSize = Math.floor(shuffledSongs.length / 3);
    const row1Songs = shuffledSongs.slice(0, chunkSize);
    const row2Songs = shuffledSongs.slice(chunkSize, chunkSize * 2);
    const row3Songs = shuffledSongs.slice(chunkSize * 2);
    
    // If any row is too small, add some transformed versions of its songs
    const ensureMinSize = (songs, minSize) => {
      if (songs.length < minSize) {
        const extra = [...songs];  // Simply duplicate without modifying song_id
        return [...songs, ...extra].slice(0, minSize);
      }
      return songs;
    };

    // Ensure each row has at least 8 items
    const row1 = ensureMinSize(row1Songs, 8);
    const row2 = ensureMinSize(row2Songs, 8);
    const row3 = ensureMinSize(row3Songs, 8);

    return { row1, row2, row3 };
  }, [validSongs]);

  // Calculate item dimensions
  const itemWidth = window.innerWidth <= 768 ? 80 : 100;
  const itemGap = window.innerWidth <= 768 ? 15 : 20;
  
  const renderRow = (songs, rowIndex) => {
    // Create a seamless loop by duplicating the content
    const items = [...songs, ...songs]; // Duplicate for seamless loop
    const baseSpeed = 30; // Base duration in seconds
    const speedMultipliers = [1.8, 1, 2.4]; // Different speeds for each row
    const duration = baseSpeed * speedMultipliers[rowIndex];
    
    // Calculate top position with mobile-friendly spacing
    const baseTop = window.innerWidth <= 768 ? 40 : 60;
    const rowGap = window.innerWidth <= 768 ? 100 : 120;
    const topPosition = baseTop + rowIndex * rowGap;
    
    return (
      <AnimatedRow 
        $opacity={0.8} 
        $top={topPosition}
        $itemWidth={itemWidth}
        $gap={itemGap}
        $duration={duration}
        $direction={rowIndex % 2 === 0 ? 'normal' : 'reverse'}
      >
        {items.map((song, i) => (
          <AlbumArt 
            key={`row${rowIndex}-${song.song_id}-${i}`} 
            $imageUrl={signedUrls[song.song_id]} 
            $opacity={0.8} 
          />
        ))}
      </AnimatedRow>
    );
  };
  
  return (
    <StartListeningCard onClick={onClick}>
      <RowsContainer>
        {renderRow(rowSequences.row1, 0)}
        {renderRow(rowSequences.row2, 1)}
        {renderRow(rowSequences.row3, 2)}
      </RowsContainer>
      <PlayButton>
        <FaPlay />
      </PlayButton>
    </StartListeningCard>
  );
};

function SongCarousel() {
  const [isStarted, setIsStarted] = useState(false);
  const [queue, setQueue] = useState([]);
  const [initialSongs, setInitialSongs] = useState([]);
  const [history, setHistory] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const { fetchRandomSongs } = useSongContext();
  const activeCardRef = useRef(null);
  const progressBarRef = useRef(null);
  const isManualTransition = useRef(false);

  // Add effect to fetch initial songs for the preview
  useEffect(() => {
    if (!isStarted && initialSongs.length === 0) {
      console.log('Fetching initial songs for preview...');
      fetchRandomSongs(40).then(songs => {  // Increased from 20 to 40
        console.log('Fetched initial songs:', songs);
        setInitialSongs(songs);
      });
    }
  }, [isStarted, initialSongs.length, fetchRandomSongs]);

  useEffect(() => {
    if (isStarted && queue.length < 3) {
      fetchRandomSongs(3 - queue.length).then(newSongs => {
        setQueue(prev => [...prev, ...newSongs]);
      });
    }
  }, [isStarted, queue.length, fetchRandomSongs]);

  // Effect to handle queue changes and playback
  useEffect(() => {
    if (!queue.length) return;
    
    let timeoutId;
    let isEffectActive = true;
    
    const initializePlayback = async () => {
      if (!activeCardRef.current) return;
      
      try {
        // Ensure we pause current playback
        activeCardRef.current.pause();
        setCurrentTime(0);
        
        // Wait for audio source to be set and loaded
        await new Promise((resolve) => {
          const checkAudio = () => {
            if (!isEffectActive) {
              resolve();
              return;
            }
            
            const audio = activeCardRef.current?.audioRef?.current;
            if (!audio || !audio.src) {
              timeoutId = setTimeout(checkAudio, 100);
              return;
            }
            
            // Wait for both metadata and enough data to start playing
            if (audio.readyState >= 3) {
              resolve();
            } else {
              const handleCanPlay = () => {
                audio.removeEventListener('canplay', handleCanPlay);
                resolve();
              };
              audio.addEventListener('canplay', handleCanPlay);
            }
          };
          checkAudio();
        });
        
        // Double check we're still active and should be playing
        if (!isEffectActive) return;
        
        // Add a small delay to ensure everything is ready
        await new Promise(resolve => setTimeout(resolve, 100));
        
        if (isPlaying && activeCardRef.current) {
          console.log('Starting playback for:', queue[0].song_title);
          await activeCardRef.current.play();
        }
      } catch (error) {
        console.error('Error initializing playback:', error);
        if (isEffectActive) {
          setIsPlaying(false);
        }
      }
    };
    
    console.log('Initializing playback for queue:', queue[0].song_title);
    initializePlayback();
    
    return () => {
      isEffectActive = false;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [queue, isPlaying]);

  // Add effect to update time and duration
  useEffect(() => {
    if (!activeCardRef.current) {
      console.log('No active card ref'); // Debug log
      return;
    }

    console.log('Setting up audio event listeners...'); // Debug log
    const currentCard = activeCardRef.current;
    
    const handleTimeUpdate = () => {
      if (currentCard.currentTime !== undefined) {
        setCurrentTime(currentCard.currentTime);
      }
    };

    const handleLoadedMetadata = () => {
      if (currentCard.duration !== undefined) {
        console.log('Duration loaded:', currentCard.duration); // Debug log
        setDuration(currentCard.duration);
      }
    };

    currentCard.addEventListener('timeupdate', handleTimeUpdate);
    currentCard.addEventListener('loadedmetadata', handleLoadedMetadata);

    return () => {
      currentCard.removeEventListener('timeupdate', handleTimeUpdate);
      currentCard.removeEventListener('loadedmetadata', handleLoadedMetadata);
    };
  }, [queue]);

  const handleStart = async () => {
    const initialSongs = await fetchRandomSongs(3);
    setHistory([]); // Ensure history is empty on start
    setQueue(initialSongs);
    setIsStarted(true);
    setIsPlaying(true);
  };

  const handleSongEnd = () => {
    if (isManualTransition.current) {
      return;
    }

    if (queue.length > 0) {
      console.log('Handling song end');
      
      const updates = () => {
        setHistory(prev => [queue[0], ...prev].slice(0, 5));
        setQueue(prev => prev.slice(1));
        setCurrentTime(0); // Reset time for next song
      };
      
      requestAnimationFrame(updates);
    } else {
      setIsPlaying(false);
    }
  };

  const handlePrevious = () => {
    if (history.length > 0) {
      if (activeCardRef.current) {
        activeCardRef.current.pause();
      }
  
      const wasPlaying = isPlaying;
      
      const updates = () => {
        const previousSong = history[0];
        const newHistory = history.slice(1);
        setHistory(newHistory);
        setQueue(prev => [previousSong, ...prev]);
        setIsPlaying(wasPlaying);
        setCurrentTime(0); // Reset time when going to previous song
        setDuration(0); // Reset duration to trigger metadata load
      };
      
      requestAnimationFrame(updates);
    }
  };

  const handleNext = () => {
    if (queue.length > 0) {
      console.log('Handling next song');
      isManualTransition.current = true;
      
      if (activeCardRef.current) {
        activeCardRef.current.pause();
      }
  
      const wasPlaying = isPlaying;
      
      const updates = () => {
        const currentSong = queue[0];
        const newQueue = queue.slice(1);
        setHistory(prev => [currentSong, ...prev].slice(0, 5));
        setQueue(newQueue);
        setIsPlaying(wasPlaying);
        setCurrentTime(0); // Reset time when going to next song
        setDuration(0); // Reset duration to trigger metadata load
      };
      
      requestAnimationFrame(updates);
      
      setTimeout(() => {
        isManualTransition.current = false;
      }, 500);
    }
  };

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleProgressBarClick = (e) => {
    if (!activeCardRef.current || !progressBarRef.current) return;

    // Only handle clicks if audio is ready
    if (!activeCardRef.current.isReady) {
      console.log('Audio not ready for seeking');
      return;
    }

    const rect = progressBarRef.current.getBoundingClientRect();
    const clickX = e.clientX - rect.left;
    const percentage = Math.max(0, Math.min(1, clickX / rect.width));
    
    const duration = activeCardRef.current.duration;
    const newTime = percentage * duration;
    
    // Set the new time
    activeCardRef.current.currentTime = newTime;
    setCurrentTime(newTime);

    // If we're paused, start playing when seeking
    if (!isPlaying) {
      setIsPlaying(true);
    }
  };

  if (!isStarted) {
    return (
      <>
        <CarouselContainer>
          <StartListeningCardContent onClick={handleStart} songs={initialSongs} />
        </CarouselContainer>
      </>
    );
  }

  return (
    <>
      <CarouselContainer>
        <CarouselTrack>
          {/* Always render two left slots - either history items or empty */}
          {history.length === 0 ? (
            <>
              <CarouselSlot $position="left" key="empty-1" />
              <CarouselSlot $position="left" key="empty-2" />
            </>
          ) : history.length === 1 ? (
            <>
              <CarouselSlot $position="left" key="empty-1" />
              <CarouselSlot $position="left" key={`${history[0].song_id}-left`}>
                <RadioCard 
                  song={history[0]}
                  hidePlayControls
                />
              </CarouselSlot>
            </>
          ) : (
            <>
              <CarouselSlot $position="left" key={`${history[1].song_id}-left`}>
                <RadioCard 
                  song={history[1]}
                  hidePlayControls
                />
              </CarouselSlot>
              <CarouselSlot $position="left" key={`${history[0].song_id}-left`}>
                <RadioCard 
                  song={history[0]}
                  hidePlayControls
                />
              </CarouselSlot>
            </>
          )}
          {/* Render current and next songs */}
          {queue.slice(0, 3).map((song, index) => {
            const position = index === 0 ? 'center' : 'right';
            const isActive = index === 0;
            
            return (
              <CarouselSlot 
                key={`${song.song_id}-${position}`}
                $active={isActive}
                $position={position}
              >
                <RadioCard 
                  ref={isActive ? activeCardRef : null}
                  song={song}
                  isPlaying={isActive && isPlaying}
                  onEnded={isActive ? handleSongEnd : undefined}
                  hidePlayControls
                />
              </CarouselSlot>
            );
          })}
        </CarouselTrack>
      </CarouselContainer>

      <Controls>
        <ProgressContainer onClick={handleProgressBarClick}>
          <ProgressBar ref={progressBarRef} $progress={(currentTime / duration) * 100 || 0} />
          <TimerContainer>
            <TimeText>{formatTime(currentTime)}</TimeText>
            <TimeText>-{formatTime(duration - currentTime)}</TimeText>
          </TimerContainer>
        </ProgressContainer>
        <ControlButtons>
          <ControlButton 
            onClick={handlePrevious}
            disabled={history.length === 0}
          >
            <FaStepBackward />
          </ControlButton>

          <ControlButton onClick={togglePlay}>
            {isPlaying ? <FaPause /> : <FaPlay />}
          </ControlButton>

          <ControlButton 
            onClick={handleNext}
            disabled={queue.length === 0}
          >
            <FaStepForward />
          </ControlButton>
        </ControlButtons>
      </Controls>
    </>
  );
}

export default SongCarousel; 