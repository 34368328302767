// src/utils/getSignedUrl.js
const MEDIA_WORKER_URL = process.env.REACT_APP_MEDIA_WORKER_URL || 'https://media.tempostock.com/generate-signed-url';

// Separate caches for different expiration times
const signedUrlCaches = {
  default: {}, // 5 min cache
  meta: {}, // 1 hour cache
  social: {} // 24 hour cache
};

export const getSignedUrl = async (filePath, expirationSeconds = 300) => {
  // Choose the appropriate cache based on expiration time
  let cacheKey = 'default';
  if (expirationSeconds >= 86400) {
    cacheKey = 'social';
  } else if (expirationSeconds >= 3600) {
    cacheKey = 'meta';
  }

  // Check cache
  if (signedUrlCaches[cacheKey][filePath]) {
    console.log(`Cache hit for filePath: ${filePath} in ${cacheKey} cache`);
    return signedUrlCaches[cacheKey][filePath];
  }

  try {
    console.log(`Requesting signed URL for filePath: ${filePath} with expiration: ${expirationSeconds}s`);
    const response = await fetch(MEDIA_WORKER_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        filePath,
        expirationSeconds 
      }),
    });

    console.log(`Media Worker response status: ${response.status}`);
    if (!response.ok) {
      const errorText = await response.text();
      console.error(`Failed to get signed URL: ${errorText}`);
      return null;
    }

    const data = await response.json();
    signedUrlCaches[cacheKey][filePath] = data.signedUrl;
    console.log(`Generated signed URL for: ${filePath} (URL hidden)`);
    return data.signedUrl;
  } catch (error) {
    console.error('Error fetching signed URL:', error);
    return null;
  }
};