// src/components/CategorySection.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SectionContainer = styled.div`
  text-align: center;
  margin: 50px 0;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 769px) and (max-width: 1280px) {
    padding: 0 15px;
  }

  @media (max-width: 768px) {
    padding: 0 15px;
  }
`;

const BlocksContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 769px) and (max-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
`;

const CategoryBlock = styled(Link)`
  aspect-ratio: 1;
  width: 100%;
  position: relative;
  border-radius: 20px;
  text-decoration: none;
  color: white;
  font-size: 22px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  background: #2E2E2E;

  @media (min-width: 769px) and (max-width: 1280px) {
    font-size: 20px;
  }

  @media (max-width: 768px) {
    font-size: 18px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const BlurImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: ${props => props.$blurDataUrl ? `url(${props.$blurDataUrl})` : 'none'};
  background-size: cover;
  background-position: center;
  filter: blur(20px);
  transform: scale(1.1);
  transition: opacity 0.3s ease-out;
  opacity: ${props => props.$isLoading ? 1 : 0};
`;

const MainImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: ${props => props.$bgImage ? `url(${props.$bgImage})` : 'none'};
  background-size: cover;
  background-position: center;
  opacity: ${props => props.$isLoaded ? 1 : 0};
  transition: opacity 0.3s ease-in-out;

  /* Soft Shader Overlay */
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(8px);
    background: rgba(0, 0, 0, 0.3);
    transition: backdrop-filter 0.3s ease, background 0.3s ease;
  }

  /* Enhance on Hover */
  ${CategoryBlock}:hover &::after {
    backdrop-filter: blur(15px);
    background: rgba(0, 0, 0, 0.4);
  }
`;

const CategoryText = styled.span`
  position: relative;
  z-index: 1;
  color: #FFFFFF;
  font-weight: bold;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
`;

// Generate a tiny blur placeholder
const generateBlurPlaceholder = (width = 20) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = width;
  canvas.height = width;

  ctx.fillStyle = '#2E2E2E';
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  return canvas.toDataURL('image/jpeg', 0.1);
};

// Move categories outside component
const CATEGORIES = [
  { name: 'Corporate', image: '/images/categories/corporate.jpg' },
  { name: 'Cinematic', image: '/images/categories/cinematic.jpg' },
  { name: 'Beats', image: '/images/categories/pop.jpg' },
  { name: 'Ambient', image: '/images/categories/ambient.jpg' },
  { name: 'Lofi', image: '/images/categories/lofi.jpg' },
];

const SectionTitle = styled.h2`
  font-family: 'DM Serif Text', serif;
  color: #2E2E2E;
  margin-bottom: 30px;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

function CategorySection() {
  // State to track loaded images
  const [loadedImages, setLoadedImages] = useState({});
  const [blurDataUrls, setBlurDataUrls] = useState({});

  useEffect(() => {
    // Generate blur placeholders for all categories
    const blurUrls = {};
    CATEGORIES.forEach(category => {
      blurUrls[category.name] = generateBlurPlaceholder();
    });
    setBlurDataUrls(blurUrls);

    // Preload all images
    CATEGORIES.forEach(category => {
      const img = new Image();
      img.onload = () => {
        setLoadedImages(prev => ({
          ...prev,
          [category.name]: true
        }));
      };
      img.src = category.image;
    });
  }, []); // Now this is safe as CATEGORIES is constant

  return (
    <SectionContainer>
      <SectionTitle>Browse by category</SectionTitle>
      <BlocksContainer>
        {CATEGORIES.map((category) => (
          <CategoryBlock
            to={`/${category.name.toLowerCase()}`}
            key={category.name}
          >
            <BlurImage 
              $blurDataUrl={blurDataUrls[category.name]}
              $isLoading={!loadedImages[category.name]}
            />
            <MainImage
              $bgImage={category.image}
              $isLoaded={loadedImages[category.name]}
            />
            <CategoryText>{category.name}</CategoryText>
          </CategoryBlock>
        ))}
      </BlocksContainer>
    </SectionContainer>
  );
}

export default CategorySection;